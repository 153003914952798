import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Subscription, catchError, filter, of, switchMap } from 'rxjs';

import { User } from '../../auth/models/user.model';
import { LoginService } from '../../auth/services/login.service';
import { Preset } from '../../models/preset.model';
import { DataDogLoggerService } from '../../services/data-dog-logger.service';
import { PresetService } from '../../ui/services/preset.service';

/**
 * ### Related UI components:
 * [FilterSetComponent, PresetFiltersComponent]
 * 
 * @description
 * This resolver is in charge of fetching the pre-set filters from a section.
 * If the page has sub-tabs or sections, you have to add the `sectionsToPreset` array with the sections to preset.
 * ```
 * {
    path: ':collection', component: FintechComponent,
    resolve: {
      presetFilters: PresetsFiltersResolver
    },
    data: { 
      presetKey: 'fintech',
      sectionsToPreset: ['scoring', 'applications'] 
    }
  },
  ```
 * 
 */
@Injectable()
export class PresetsFiltersResolver {

  private subscriptions: Subscription[] = [];
  private companyId: number;

  constructor(
    private presetService: PresetService,
    private router: Router,
    private route: ActivatedRoute,
    private dataDogLoggerService: DataDogLoggerService,
    private loginService: LoginService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot
  ) {
    this.companyId = +route.parent.paramMap.get('companyId');

    return this.loginService.getCurrentUser()
      .pipe(
        filter(user => !!user), // if user exists
        switchMap<User, any>((user: User) => {
          const sectionsToPresets: string[] = route.data['sectionsToPreset'];
          const routeCurrentCollection: string = route.params.collection;
          const presetKey: string = this.getPresetKey(route.data['presetKey'], routeCurrentCollection);

          if (routeCurrentCollection && !sectionsToPresets.includes(routeCurrentCollection)) return

          return this.applyPresets(user, presetKey);
        }),
        catchError(() => of(null))
      );
  }

  private applyQueryParams(preset: Preset): void {
    const [url, originalInitialFilters] = this.router.url.split('?');
    const queryParams = this.route.snapshot.queryParamMap;
    const urlParams = new URLSearchParams(preset?.data?.queryParams ?? originalInitialFilters);

    urlParams.forEach((value, key) => {
      queryParams[key] = value;
    });
    delete queryParams['params'];

    if (preset) {
      // keep information to share with [[PresetFiltersComponent]]
      this.presetService.setPresetFilter(preset, originalInitialFilters)
    }

    this.router.navigate([url], { queryParams });
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  };

  private applyPresets(user, presetKey: string): number {
    this.presetService.setPresetFilter(null);

    return this.subscriptions.push(
      this.presetService.get(this.companyId, user.id, `filters-${presetKey}`, false)
        .subscribe({
          next: presets => {
            // When returned from cache, setTimeout allows to render
            setTimeout(() => {
              this.applyQueryParams(presets[0]);
            });
          },
          error: error => {
            // Non fatal error
            this.dataDogLoggerService.warn(error.message, error.error);
          }
        }));
  }

  private getPresetKey(key: string, routeCurrentCollection: string): string {
    let presetKey = key || '';

    if (routeCurrentCollection) {
      presetKey += `${presetKey ? '-' : ''}${routeCurrentCollection}`;
    }

    return presetKey;
  }
}
